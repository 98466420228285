import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select } from '@mui/material';
import DashboardNavbar from 'components/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useEffect, useState } from 'react';
import StyledFileInput from './StyledFileInput';
import { createAvatar } from 'services/avatars/create-avatar';
import { getAvatars } from 'services/avatars/get-avatars';
import { deleteAvatar } from 'services/avatars/delete-avatar';
import { editAvatar } from 'services/avatars/edit-avatar';

export default function Avatars() {
  const [avatars, setAvatars] = useState([]);
  const [openAddAvatar, setOpenAddAvatar] = useState(false);
  const [name, setName] = useState('');
  const [gems, setGems] = useState('');
  const [coins, setCoins] = useState('');
  const [smallImage, setSmallImage] = useState('');
  const [bigImage, setBigImage] = useState('');
  const [textImage, setTextImage] = useState('');
  const [unrealAvatarId, setUnrealAvatarId] = useState('');
  const [defaultSkinUnrealId, setDefaultSkinUnrealId] = useState('');
  const [rarity, setRarity] = useState('');

  const [openEditAvatar, setOpenEditAvatar] = useState(false);
  const [editId, setEditId] = useState(null);
  const [openDeleteAvatar, setOpenDeleteAvatar] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');

  const handleAvatarMenu = () => setOpenAddAvatar(!openAddAvatar);
  const handleOpenDeleteAvatar = () => setOpenDeleteAvatar(!openDeleteAvatar);
  const handleOpenEditAvatar = () => setOpenEditAvatar(!openEditAvatar);

  const selectValues = [
    {
      value: 'COMMON',
      name: 'Common',
      color: 'grey'
    },
    {
      value: 'RARE',
      name: 'Rare',
      color: 'blue'
    },
    {
      value: 'EPIC',
      name: 'Epic',
      color: 'purple'
    },
    {
      value: 'LEGENDARY',
      name: 'Legendary',
      color: 'gold'
    },
    {
      value: 'SUPREME',
      name: 'Supreme',
      color: 'orange'
    }
  ];

  async function initData() {
    const res = await getAvatars();
    setAvatars(res?.data);
  }

  useEffect(() => {
    // call on init load
    initData();
  }, []);

  async function sendData() {
    const res = await createAvatar(
      name,
      gems,
      0,
      unrealAvatarId,
      defaultSkinUnrealId,
      rarity,
      smallImage,
      bigImage,
      textImage
    );

    if (res.status === 200 || res.status === 201) {
      // alert for successful creeation, clear input states and close the menu!
      alert('Avatar creation successful!');
    } else {
      // alert the error!
      alert('Something went wrong!');
    }
    setName('');
    setGems('');
    setCoins('');
    setSmallImage('');
    setBigImage('');
    setTextImage('');
    handleAvatarMenu();
    setUnrealAvatarId('');
    setDefaultSkinUnrealId('');
    setRarity('');
    // fetch updated listing
    initData();
  }

  async function handleDelete() {
    // ensure the avatar is deleted before fetching the new listing!
    await deleteAvatar(deleteId);
    handleOpenDeleteAvatar();
    setDeleteId(null);
    setDeleteName('');
    initData();
  }

  async function handleEdit() {
    // ensure the avatar is eddited before fetching the new listing!
    const res = await editAvatar(editId, name, gems, 0, unrealAvatarId, defaultSkinUnrealId, rarity);
    if (res.status === 200 || res.status === 201) {
      alert('Edit successful!');
    } else {
      alert('Something went wrong!');
    }
    setName('');
    setGems('');
    setCoins('');
    setSmallImage('');
    setBigImage('');
    setTextImage('');
    setUnrealAvatarId('');
    setDefaultSkinUnrealId('');
    setRarity('');
    handleOpenEditAvatar();
    setEditId(null);
    initData();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={1} sx={{ marginTop: '20px' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <MDTypography sx={{ fontSize: '16px', fontWeight: '600' }}>Avatars</MDTypography> */}
          <MDButton
            color='info'
            onClick={() => {
              setName('');
              setGems('');
              setCoins('');
              setSmallImage('');
              setBigImage('');
              setTextImage('');
              handleAvatarMenu();
              setUnrealAvatarId('');
              setDefaultSkinUnrealId('');
              setRarity('');
            }}
          >
            Add new avatar
          </MDButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}
        >
          {avatars && avatars.length > 0 ? (
            avatars.map((avatar) => {
              return (
                <Grid item xs={3} sx={{ padding: '15px' }}>
                  <MDBox
                    sx={{
                      background: '#27314f',
                      borderRadius: '10px',
                      border: '0.5px solid #fff',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '15px'
                    }}
                  >
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      {avatar.name}
                    </MDTypography>
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      Rarity:{' '}
                      <span
                        style={{
                          color: selectValues.filter((obj) => obj?.value === avatar?.skins[0]?.rarity)[0]?.color
                        }}
                      >
                        {avatar?.skins[0]?.rarity}
                      </span>
                    </MDTypography>
                    <img src={avatar?.skins[0]?.bigImage?.url} style={{ width: '200px' }}></img>
                    <MDBox sx={{ display: 'flex', padding: '10px', gap: '10px' }}>
                      <MDButton
                        onClick={() => {
                          handleOpenEditAvatar();
                          setEditId(avatar?.id);
                          setName(avatar?.name);
                          setGems(avatar?.skins[0]?.priceGems);
                          setCoins(avatar?.skins[0]?.priceCoins);
                          setSmallImage(avatar?.skins[0]?.smallImage?.url);
                          setBigImage(avatar?.skins[0]?.bigImage?.url);
                          setTextImage(avatar?.skins[0].coverImage?.url);
                          setUnrealAvatarId(avatar?.unrealAvatarId);
                          setDefaultSkinUnrealId(avatar?.skins[0]?.unrealSkinId);
                          setRarity(avatar?.skins[0]?.rarity);
                        }}
                      >
                        Edit Avatar
                      </MDButton>
                      <MDButton
                        onClick={() => {
                          handleOpenDeleteAvatar();
                          setDeleteId(avatar?.id);
                          setDeleteName(avatar?.name);
                        }}
                      >
                        Delete Avatar
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Grid>
              );
            })
          ) : (
            <MDBox
              sx={{
                width: '100%',
                textAlign: 'center',
                paddingBottom: '30px'
              }}
            >
              <MDTypography sx={{ fontSize: '14px' }}>No avatars available.</MDTypography>
            </MDBox>
          )}
        </Grid>
        <Dialog
          open={openAddAvatar}
          onClose={handleAvatarMenu}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Add new avatar`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            <MDTypography sx={{ fontSize: '16px' }}>Avatar name</MDTypography>
            <MDInput
              type='text'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Price Gems</MDTypography>
            <MDInput
              type='text'
              value={gems}
              onChange={(e) => {
                setGems(e.target.value);
              }}
            />
            {/* <MDTypography sx={{ fontSize: '16px' }}>Price Coins</MDTypography>
            <MDInput
              type='text'
              value={coins}
              onChange={(e) => {
                setCoins(e.target.value);
              }}
            /> */}
            <MDTypography sx={{ fontSize: '16px' }}>Unreal Avatar Id</MDTypography>
            <MDInput
              type='number'
              value={unrealAvatarId}
              onChange={(e) => {
                setUnrealAvatarId(e.target.value);
              }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Default Unreal Skin Id</MDTypography>
            <MDInput
              type='number'
              value={defaultSkinUnrealId}
              onChange={(e) => {
                setDefaultSkinUnrealId(e.target.value);
              }}
            />

            <MDTypography sx={{ fontSize: '16px' }}>Rarity</MDTypography>

            <FormControl fullWidth variant='outlined'>
              <Select
                id='rarity'
                value={rarity}
                onChange={(e) => setRarity(e.target.value)}
                style={{ padding: '0.75rem 0' }}
              >
                {selectValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Small Image</MDTypography>
            <StyledFileInput setImage={setSmallImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Big Image</MDTypography>
            <StyledFileInput setImage={setBigImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Text Image</MDTypography>
            <StyledFileInput setImage={setTextImage} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleAvatarMenu}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                sendData();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDeleteAvatar}
          onClose={handleOpenDeleteAvatar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Are you sure you want to delete ${deleteName}?`}</DialogTitle>
          <DialogActions>
            <MDButton
              variant='text'
              color='error'
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </MDButton>
            <MDButton
              variant='text'
              onClick={() => {
                handleOpenDeleteAvatar();
              }}
            >
              No
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openEditAvatar}
          onClose={handleOpenEditAvatar}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' sx={{ width: '100%', textAlign: 'center' }}>{`Edit avatar`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            <MDTypography sx={{ fontSize: '16px' }}>Avatar name</MDTypography>
            <MDInput
              type='text'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Price Gems</MDTypography>
            <MDInput
              type='text'
              value={gems}
              onChange={(e) => {
                setGems(e.target.value);
              }}
            />
            {/* <MDTypography sx={{ fontSize: '16px' }}>Price Coins</MDTypography>
            <MDInput
              type='text'
              value={coins}
              onChange={(e) => {
                setCoins(e.target.value);
              }}
            /> */}
            <MDTypography sx={{ fontSize: '16px' }}>Unreal Avatar Id</MDTypography>
            <MDInput
              type='number'
              value={unrealAvatarId}
              onChange={(e) => {
                setUnrealAvatarId(e.target.value);
              }}
              style={{ width: '100%' }}
            />

            <MDTypography sx={{ fontSize: '16px' }}>Default Unreal Skin Id</MDTypography>
            <MDInput
              type='number'
              value={defaultSkinUnrealId}
              onChange={(e) => {
                setDefaultSkinUnrealId(e.target.value);
              }}
              disabled
            />

            <MDTypography sx={{ fontSize: '16px' }}>Rarity</MDTypography>

            <FormControl fullWidth variant='outlined'>
              <Select
                id='rarity'
                value={rarity}
                onChange={(e) => setRarity(e.target.value)}
                style={{ padding: '0.75rem 0' }}
              >
                {selectValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Small Image</MDTypography>
            <StyledFileInput disabled setImage={setSmallImage} image={smallImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Big Image</MDTypography>
            <StyledFileInput disabled setImage={setBigImage} image={bigImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Text Image</MDTypography>
            <StyledFileInput disabled setImage={setTextImage} image={textImage} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleOpenEditAvatar}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                handleEdit();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </DashboardLayout>
  );
}
