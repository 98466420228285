import useAxios from 'hooks/useAxios';

export async function editAvatar(id, name, priceGems, priceCoins, unrealAvatarId, defaultSkinUnrealId, rarity) {
  const api = useAxios();
  const payload = {
    name,
    priceGems,
    priceCoins,
    unrealAvatarId,
    defaultSkinUnrealId,
    rarity
  };

  try {
    const res = await api.patch(`/avatars/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
}
