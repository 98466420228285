import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import DashboardNavbar from 'components/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useEffect, useRef, useState } from 'react';
import StyledFileInput from './StyledFileInput';
import { getSkins } from 'services/skins/get-skins';
import { createSkin } from 'services/skins/create-skin';
import { deleteSkin } from 'services/skins/delete-skin';
import { editSkin } from 'services/skins/edit-skin';
import { getAvatars } from 'services/avatars/get-avatars';

import InfiniteScroll from 'react-infinite-scroll-component';
import { changePicture } from 'services/skins/change-picture';

export default function Skins() {
  const [skins, setSkins] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [name, setName] = useState('');
  const [gems, setGems] = useState('');
  const [coins, setCoins] = useState('');
  const [smallImage, setSmallImage] = useState('');
  const [bigImage, setBigImage] = useState('');
  const [textImage, setTextImage] = useState('');
  const [unrealAvatarId, setUnrealAvatarId] = useState('');

  // selected avatarId
  const [avatar, setAvatar] = useState('');

  // list available avatarIds
  const [avatars, setAvatars] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null); // Ref for detecting outside clicks

  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [openDelete, setopenDelete] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');

  const [rarity, setRarity] = useState('');

  const selectValues = [
    {
      value: 'COMMON',
      name: 'Common',
      color: 'grey'
    },
    {
      value: 'RARE',
      name: 'Rare',
      color: 'blue'
    },
    {
      value: 'EPIC',
      name: 'Epic',
      color: 'purple'
    },
    {
      value: 'LEGENDARY',
      name: 'Legendary',
      color: 'gold'
    },
    {
      value: 'SUPREME',
      name: 'Supreme',
      color: 'orange'
    }
  ];

  const handleCreateMenu = () => {
    setOpenAdd(!openAdd);
    setAvatars([]);
  };
  const handleopenDelete = () => setopenDelete(!openDelete);
  const handleOpenEdit = () => setOpenEdit(!openEdit);

  async function initData() {
    const res = await getSkins();
    setSkins(res?.data);
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // fetch avatars
  async function fetchAvatars() {
    const res = await getAvatars(20, page);

    if (res.data.length === 0) {
      setHasMore(false);
      return;
    }

    setAvatars((prev) => [...prev, ...res.data]);
    setPage((prev) => prev + 1);
  }

  useEffect(() => {
    // call on init load
    initData();
  }, []);

  async function sendData() {
    const res = await createSkin(name, gems, 0, unrealAvatarId, avatar.id, smallImage, bigImage, textImage, rarity);

    if (res.status === 200 || res.status === 201) {
      // alert for successful creeation, clear input states and close the menu!
      alert('Skin creation successful!');
    } else {
      // alert the error!
      alert('Something went wrong!');
    }
    setName('');
    setGems('');
    setCoins('');
    setSmallImage('');
    setBigImage('');
    setTextImage('');
    handleCreateMenu();
    setUnrealAvatarId('');
    setRarity('');
    // fetch updated listing
    initData();
  }

  async function handleDelete() {
    // ensure the avatar is deleted before fetching the new listing!
    await deleteSkin(deleteId);
    handleopenDelete();
    setDeleteId(null);
    setDeleteName('');
    initData();
  }

  async function handleEdit() {
    const res2 = await changePicture(editId, smallImage, bigImage, textImage);
    if (!res2.status === 200 || !res2.status === 201) {
      alert('Change image request failed');
      return;
    }
    // ensure the avatar is eddited before fetching the new listing!
    const res = await editSkin(editId, name, unrealAvatarId, gems, 0, rarity);
    if (res.status === 200 || res.status === 201) {
      alert('Edit successful!');
    } else {
      alert('Something went wrong!');
    }
    setName('');
    setGems('');
    setCoins('');
    setSmallImage('');
    setBigImage('');
    setTextImage('');
    handleOpenEdit();
    setEditId(null);
    setAvatar('');
    setUnrealAvatarId('');
    setRarity('');
    // fetch updated listing
    initData();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={1} sx={{ marginTop: '20px' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <MDTypography sx={{ fontSize: '16px', fontWeight: '600' }}>Avatars</MDTypography> */}
          <MDButton
            color='info'
            onClick={() => {
              fetchAvatars();
              setName('');
              setGems('');
              setCoins('');
              setAvatar('');
              setSmallImage('');
              setBigImage('');
              setTextImage('');
              handleCreateMenu();
              setRarity('');
            }}
          >
            Add new skin
          </MDButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}
        >
          {skins && skins?.length > 0 ? (
            skins?.map((skin) => {
              return (
                <Grid item xs={3} sx={{ padding: '15px' }}>
                  <MDBox
                    sx={{
                      background: '#27314f',
                      borderRadius: '10px',
                      border: '0.5px solid #fff',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '15px'
                    }}
                  >
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      Skin: {skin?.name}
                    </MDTypography>
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      Avatar: {skin?.avatar?.name}
                    </MDTypography>
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      Rarity:{' '}
                      <span style={{ color: selectValues.filter((obj) => obj?.value === skin?.rarity)[0]?.color }}>
                        {skin?.rarity}
                      </span>
                    </MDTypography>
                    <img src={skin?.bigImage?.url} style={{ width: '200px' }}></img>
                    <MDBox sx={{ display: 'flex', padding: '10px', gap: '10px' }}>
                      <MDButton
                        onClick={() => {
                          handleOpenEdit();
                          setEditId(skin?.id);
                          setName(skin?.name);
                          setGems(skin?.priceGems);
                          setCoins(skin?.priceCoins);
                          setAvatar(skin.avatar);
                          setSmallImage(skin?.smallImage?.url);
                          setBigImage(skin?.bigImage?.url);
                          setTextImage(skin?.coverImage?.url);
                          setRarity(skin?.rarity);
                          setUnrealAvatarId(skin?.unrealSkinId);
                        }}
                      >
                        Edit Skin
                      </MDButton>
                      <MDButton
                        onClick={() => {
                          handleopenDelete();
                          setDeleteId(skin?.id);
                          setDeleteName(skin?.name);
                        }}
                      >
                        Delete Skin
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Grid>
              );
            })
          ) : (
            <MDBox
              sx={{
                width: '100%',
                textAlign: 'center',
                paddingBottom: '30px'
              }}
            >
              <MDTypography sx={{ fontSize: '14px' }}>No Skins available.</MDTypography>
            </MDBox>
          )}
        </Grid>
        <Dialog
          open={openAdd}
          onClose={handleCreateMenu}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Add new skin`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            <MDTypography sx={{ fontSize: '16px' }}>Skin name</MDTypography>
            <MDInput
              type='text'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Price Gems</MDTypography>
            <MDInput
              type='text'
              value={gems}
              onChange={(e) => {
                setGems(e.target.value);
              }}
            />
            {/* <MDTypography sx={{ fontSize: '16px' }}>Price Coins</MDTypography>
            <MDInput
              type='text'
              value={coins}
              onChange={(e) => {
                setCoins(e.target.value);
              }}
            /> */}
            <MDTypography sx={{ fontSize: '16px' }}>Unreal Skin Id</MDTypography>
            <MDInput
              type='number'
              value={unrealAvatarId}
              onChange={(e) => {
                setUnrealAvatarId(e.target.value);
              }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Rarity</MDTypography>

            <FormControl fullWidth variant='outlined'>
              <Select
                id='rarity'
                value={rarity}
                onChange={(e) => setRarity(e.target.value)}
                style={{ padding: '0.75rem 0' }}
              >
                {selectValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDTypography sx={{ fontSize: '16px' }}>Avatar</MDTypography>
            <div ref={dropdownRef} style={{ position: 'relative' }}>
              {/* ✅ Button to open dropdown */}
              <div
                onClick={() => setIsOpen(!isOpen)}
                style={{
                  padding: '10px',
                  border: '1px solid #ccc',
                  cursor: 'pointer',
                  borderRadius: '5px',
                  background: '#fff',
                  color: 'black'
                }}
              >
                {avatar ? avatar?.name : 'Select an avatar'}
              </div>

              {/* ✅ Dropdown menu */}
              {isOpen && (
                <ul
                  id='avatars'
                  // onScroll={handleScroll} // Detects scrolling
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: 0,
                    width: '100%',
                    maxHeight: '200px',
                    overflowY: 'auto',
                    background: '#fff',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    listStyle: 'none',
                    padding: 0,
                    margin: 0,
                    zIndex: 10
                  }}
                >
                  {/* ✅ Render game items */}
                  <InfiniteScroll
                    dataLength={avatars?.length}
                    next={fetchAvatars}
                    hasMore={hasMore}
                    scrollableTarget='games'
                  >
                    {avatars?.map((av) => (
                      <li
                        value={avatar?.id}
                        key={av?.id}
                        onClick={() => {
                          setAvatar(av);
                          setIsOpen(false);
                        }}
                        style={{
                          padding: '10px',
                          cursor: 'pointer',
                          borderBottom: '1px solid #eee',
                          background: av?.id === avatar?.id ? '#e5e5e5' : '#fff',
                          color: 'black'
                        }}
                      >
                        {av?.name}
                      </li>
                    ))}
                  </InfiniteScroll>

                  {/* ✅ Loading indicator */}
                  {loading && (
                    <li style={{ padding: '10px', textAlign: 'center' }}>
                      <CircularProgress size={24} />
                    </li>
                  )}
                </ul>
              )}
            </div>

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Small Image</MDTypography>
            <StyledFileInput setImage={setSmallImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Big Image</MDTypography>
            <StyledFileInput setImage={setBigImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Text Image</MDTypography>
            <StyledFileInput setImage={setTextImage} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleCreateMenu}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                sendData();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDelete}
          onClose={handleopenDelete}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Are you sure you want to delete ${deleteName}?`}</DialogTitle>
          <DialogActions>
            <MDButton
              variant='text'
              color='error'
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </MDButton>
            <MDButton
              variant='text'
              onClick={() => {
                handleopenDelete();
              }}
            >
              No
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openEdit}
          onClose={handleOpenEdit}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' sx={{ width: '100%', textAlign: 'center' }}>{`Edit skin`}</DialogTitle>
          <DialogContent>
            <MDTypography sx={{ fontSize: '16px' }}>Skin name</MDTypography>
            <MDInput
              style={{ width: '100%' }}
              type='text'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Price Gems</MDTypography>
            <MDInput
              style={{ width: '100%' }}
              type='text'
              value={gems}
              onChange={(e) => {
                setGems(e.target.value);
              }}
            />
            {/* <MDTypography sx={{ fontSize: '16px' }}>Price Coins</MDTypography>
            <MDInput
              style={{ width: '100%' }}
              type='text'
              value={coins}
              onChange={(e) => {
                setCoins(e.target.value);
              }}
            /> */}
            <MDTypography sx={{ fontSize: '16px' }}>Unreal Skin Id</MDTypography>
            <MDInput
              type='number'
              value={unrealAvatarId}
              onChange={(e) => {
                setUnrealAvatarId(e.target.value);
              }}
              style={{ width: '100%' }}
            />
            <MDTypography sx={{ fontSize: '16px' }}>Rarity</MDTypography>
            <FormControl fullWidth variant='outlined'>
              <Select
                id='rarity'
                value={rarity}
                onChange={(e) => setRarity(e.target.value)}
                style={{ padding: '0.75rem 0' }}
              >
                {selectValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MDTypography sx={{ fontSize: '16px' }}>Avatar</MDTypography>
            <MDInput type='text' value={avatar?.name} disabled style={{ width: '100%' }} />
            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Small Image</MDTypography>
            <StyledFileInput setImage={setSmallImage} image={smallImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Big Image</MDTypography>
            <StyledFileInput setImage={setBigImage} image={bigImage} />

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Text Image</MDTypography>
            <StyledFileInput setImage={setTextImage} image={textImage} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleOpenEdit}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                handleEdit();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </DashboardLayout>
  );
}
