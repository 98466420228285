import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@mui/material';
import DashboardNavbar from 'components/DashboardNavbar';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDInput from 'components/MDInput';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useEffect, useRef, useState } from 'react';
import StyledFileInput from './StyledFileInput';
import { getSkins } from 'services/skins/get-skins';
import { createSkin } from 'services/skins/create-skin';
import { deleteSkin } from 'services/skins/delete-skin';
import { editSkin } from 'services/skins/edit-skin';
import { getAvatars } from 'services/avatars/get-avatars';

import InfiniteScroll from 'react-infinite-scroll-component';
import { createItem } from 'services/items/create-item';
import { getItems } from 'services/items/get-items';
import { deleteItem } from 'services/items/delete-item';
import { editItem } from 'services/items/edit-item';

export default function Items() {
  const [items, setItems] = useState([]);
  const [openAdd, setOpenAdd] = useState(false);
  const [name, setName] = useState('');
  const [gems, setGems] = useState('');
  const [coins, setCoins] = useState('');
  const [rarity, setRarity] = useState('');
  const [type, setType] = useState('');
  const [image, setImage] = useState('');
  const [unrealItemId, setUnrealItemId] = useState('');

  const selectValues = [
    {
      value: 'COMMON',
      name: 'Common',
      color: 'grey'
    },
    {
      value: 'RARE',
      name: 'Rare',
      color: 'blue'
    },
    {
      value: 'EPIC',
      name: 'Epic',
      color: 'purple'
    },
    {
      value: 'LEGENDARY',
      name: 'Legendary',
      color: 'gold'
    },
    {
      value: 'SUPREME',
      name: 'Supreme',
      color: 'orange'
    }
  ];

  const typeValues = [
    {
      value: 'CLOTHING',
      name: 'Clothing'
    },
    {
      value: 'CHARM',
      name: 'Charm'
    },
    {
      value: 'VEHICLE',
      name: 'Vehicle'
    },
    {
      value: 'PET',
      name: 'Pet'
    }
  ];

  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [openDelete, setopenDelete] = useState();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteName, setDeleteName] = useState('');

  const handleCreateMenu = () => setOpenAdd(!openAdd);
  const handleopenDelete = () => setopenDelete(!openDelete);
  const handleOpenEdit = () => setOpenEdit(!openEdit);

  async function initData() {
    const res = await getItems();
    setItems(res?.data);
  }

  useEffect(() => {
    // call on init load
    initData();
  }, []);

  async function sendData() {
    const res = await createItem(name, gems, 0, type, rarity, image, unrealItemId);

    if (res.status === 200 || res.status === 201) {
      // alert for successful creeation, clear input states and close the menu!
      alert('Item creation successful!');
    } else {
      // alert the error!
      alert('Something went wrong!');
    }
    setName('');
    setRarity('');
    setImage('');
    setGems('');
    setCoins('');
    setType('');
    setUnrealItemId('');
    handleCreateMenu();
    // fetch updated listing
    initData();
  }

  async function handleDelete() {
    // ensure the avatar is deleted before fetching the new listing!
    await deleteItem(deleteId);
    handleopenDelete();
    setDeleteId(null);
    setDeleteName('');
    initData();
  }

  async function handleEdit() {
    // ensure the avatar is eddited before fetching the new listing!
    const res = await editItem(editId, name, gems, 0, type, rarity, unrealItemId);
    if (res.status === 200 || res.status === 201) {
      alert('Edit successful!');
    } else {
      alert('Something went wrong!');
    }
    setName('');
    setRarity('');
    handleOpenEdit();
    setEditId(null);
    setGems('');
    setCoins('');
    setType('');
    setUnrealItemId('');
    // fetch updated listing
    initData();
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={1} sx={{ marginTop: '20px' }}>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <MDTypography sx={{ fontSize: '16px', fontWeight: '600' }}>Avatars</MDTypography> */}
          <MDButton
            color='info'
            onClick={() => {
              setName('');
              setRarity('');
              setImage('');
              setGems('');
              setCoins('');
              setType('');
              setUnrealItemId('');
              handleCreateMenu();
            }}
          >
            Add new item
          </MDButton>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'wrap' }}
        >
          {items && items?.length > 0 ? (
            items?.map((item) => {
              return (
                <Grid item xs={3} sx={{ padding: '15px' }}>
                  <MDBox
                    sx={{
                      background: '#27314f',
                      borderRadius: '10px',
                      border: '0.5px solid #fff',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingBottom: '15px'
                    }}
                  >
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      Item: {item?.name}
                    </MDTypography>
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      Rarity:{' '}
                      <span style={{ color: selectValues.filter((obj) => obj?.value === item?.rarity)[0]?.color }}>
                        {item?.rarity}
                      </span>
                    </MDTypography>
                    <MDTypography
                      sx={{
                        padding: '10px 0',
                        width: '100%',
                        textAlign: 'center',
                        background: '#121c35',
                        borderRadius: '10px 10px 0 0',
                        fontWeight: '600',
                        fontSize: '16px',
                        color: '#fff'
                      }}
                    >
                      Type: <span>{item?.type}</span>
                    </MDTypography>
                    <img src={item?.file?.url} style={{ width: '200px' }}></img>
                    <MDBox sx={{ display: 'flex', padding: '10px', gap: '10px' }}>
                      <MDButton
                        onClick={() => {
                          handleOpenEdit();
                          setEditId(item?.id);
                          setName(item?.name);
                          setRarity(item?.rarity);
                          setImage(item?.file?.url);
                          setGems(item?.priceGems);
                          setCoins(item?.priceCoins);
                          setType(item?.type);
                          setUnrealItemId(item?.unrealItemId);
                        }}
                      >
                        Edit Item
                      </MDButton>
                      <MDButton
                        onClick={() => {
                          handleopenDelete();
                          setDeleteId(item?.id);
                          setDeleteName(item?.name);
                        }}
                      >
                        Delete Item
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </Grid>
              );
            })
          ) : (
            <MDBox
              sx={{
                width: '100%',
                textAlign: 'center',
                paddingBottom: '30px'
              }}
            >
              <MDTypography sx={{ fontSize: '14px' }}>No Items available.</MDTypography>
            </MDBox>
          )}
        </Grid>
        <Dialog
          open={openAdd}
          onClose={handleCreateMenu}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Add new item`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            <MDTypography sx={{ fontSize: '16px' }}>Item name</MDTypography>
            <MDInput
              type='text'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <MDTypography sx={{ fontSize: '16px' }}>Gems price</MDTypography>
            <MDInput
              type='number'
              value={gems}
              onChange={(e) => {
                setGems(e.target.value);
              }}
            />

            {/* <MDTypography sx={{ fontSize: '16px' }}>Coins price</MDTypography>
            <MDInput
              type='number'
              value={coins}
              onChange={(e) => {
                setCoins(e.target.value);
              }}
            /> */}

            <MDTypography sx={{ fontSize: '16px' }}>Unreal Item Id</MDTypography>
            <MDInput
              type='number'
              value={unrealItemId}
              onChange={(e) => {
                setUnrealItemId(e.target.value);
              }}
            />

            <MDTypography sx={{ fontSize: '16px' }}>Type</MDTypography>

            <FormControl fullWidth variant='outlined'>
              <Select id='type' value={type} onChange={(e) => setType(e.target.value)} style={{ padding: '0.75rem 0' }}>
                {typeValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDTypography sx={{ fontSize: '16px' }}>Rarity</MDTypography>

            <FormControl fullWidth variant='outlined'>
              <Select
                id='rarity'
                value={rarity}
                onChange={(e) => setRarity(e.target.value)}
                style={{ padding: '0.75rem 0' }}
              >
                {selectValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Small Image</MDTypography>
            <StyledFileInput setImage={setImage} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleCreateMenu}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                sendData();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDelete}
          onClose={handleopenDelete}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ width: '100%', textAlign: 'center' }}
          >{`Are you sure you want to delete ${deleteName}?`}</DialogTitle>
          <DialogActions>
            <MDButton
              variant='text'
              color='error'
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </MDButton>
            <MDButton
              variant='text'
              onClick={() => {
                handleopenDelete();
              }}
            >
              No
            </MDButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openEdit}
          onClose={handleOpenEdit}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title' sx={{ width: '100%', textAlign: 'center' }}>{`Edit skin`}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: '5px', padding: '20px 30px' }}>
            <MDTypography sx={{ fontSize: '16px' }}>Item name</MDTypography>
            <MDInput
              type='text'
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />

            <MDTypography sx={{ fontSize: '16px' }}>Gems price</MDTypography>
            <MDInput
              type='number'
              value={gems}
              onChange={(e) => {
                setGems(e.target.value);
              }}
            />

            {/* <MDTypography sx={{ fontSize: '16px' }}>Coins price</MDTypography>
            <MDInput
              type='number'
              value={coins}
              onChange={(e) => {
                setCoins(e.target.value);
              }}
            /> */}

            <MDTypography sx={{ fontSize: '16px' }}>Unreal Item Id</MDTypography>
            <MDInput
              type='number'
              value={unrealItemId}
              onChange={(e) => {
                setUnrealItemId(e.target.value);
              }}
            />

            <MDTypography sx={{ fontSize: '16px' }}>Type</MDTypography>

            <FormControl fullWidth variant='outlined'>
              <Select id='type' value={type} onChange={(e) => setType(e.target.value)} style={{ padding: '0.75rem 0' }}>
                {typeValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDTypography sx={{ fontSize: '16px' }}>Rarity</MDTypography>
            <FormControl fullWidth variant='outlined'>
              <Select
                id='rarity'
                value={rarity}
                onChange={(e) => setRarity(e.target.value)}
                style={{ padding: '0.75rem 0' }}
              >
                {selectValues?.map((e, i) => (
                  <MenuItem key={i} value={e?.value}>
                    {e?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MDTypography sx={{ fontSize: '16px', marginTop: '10px' }}>Small Image</MDTypography>
            <StyledFileInput setImage={setImage} image={image} />
          </DialogContent>
          <DialogActions>
            <MDButton variant='text' onClick={handleOpenEdit}>
              Close
            </MDButton>
            <MDButton
              color='info'
              onClick={() => {
                handleEdit();
              }}
            >
              Save
            </MDButton>
          </DialogActions>
        </Dialog>
      </Grid>
    </DashboardLayout>
  );
}
