import useAxios from 'hooks/useAxios';

export async function createAvatar(
  name,
  priceGems,
  priceCoins,
  unrealAvatarId,
  defaultSkinUnrealId,
  rarity,
  smallImage,
  bigImage,
  textImage
) {
  const api = useAxios();
  const formData = new FormData();
  formData.append('name', name);
  formData.append('priceGems', priceGems);
  formData.append('priceCoins', priceCoins);
  formData.append('unrealAvatarId', unrealAvatarId);
  formData.append('defaultSkinUnrealId', defaultSkinUnrealId);
  formData.append('rarity', rarity);
  formData.append('smallImage', smallImage);
  formData.append('bigImage', bigImage);
  formData.append('coverImage', textImage);

  try {
    const res = await api.post('/avatars', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res;
  } catch (err) {
    return err;
  }
}
