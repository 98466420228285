import React, { useState } from 'react';
import { Button, Input } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useMaterialUIController } from 'context';

const StyledFileInput = ({ setImage, image }) => {
  const [file, setFile] = useState(null);

  const onDrop = (acceptedFiles) => {
    // Handle dropped files here
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setImage(selectedFile);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop
    // accept: "image/jpeg, image/png," //accepted file types (add more, if needed)
  });

  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  let descriptionColor = 'black';
  if (darkMode) {
    descriptionColor = 'white';
  }

  return (
    <div>
      <div {...getRootProps()} style={dropzoneStyle}>
        <input type='file' id='my-element' {...getInputProps()} />
        <p style={{ color: descriptionColor }}>
          {isDragActive ? 'Drop the file here' : 'Drop a file here or click to select one'}
        </p>
      </div>
      {file ? (
        <div>
          <img src={URL.createObjectURL(file)} alt='Selected' style={previewImageStyle} />
          <Button
            style={{ color: 'white', position: 'relative', bottom: '25px' }}
            variant='contained'
            onClick={() => setFile(null)}
          >
            Remove
          </Button>
        </div>
      ) : image ? (
        <div>
          <img src={image} alt='Selected' style={previewImageStyle} />
          <Button
            style={{ color: 'white', position: 'relative', bottom: '25px' }}
            variant='contained'
            onClick={() => {
              setFile(null);
              setImage(null);
            }}
          >
            Remove
          </Button>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const dropzoneStyle = {
  border: '2px dashed #ccc',
  borderRadius: '4px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer'
};

const previewImageStyle = {
  maxWidth: '100%',
  maxHeight: '100px',
  marginTop: '10px',
  padding: '10px'
};

export default StyledFileInput;
