import useAxios from 'hooks/useAxios';

export async function editItem(id, name, gems, coins, type, rarity, unrealItemId) {
  const api = useAxios();
  const payload = {
    name,
    priceGems: gems,
    priceCoins: coins,
    type,
    rarity,
    unrealItemId
  };

  try {
    const res = await api.patch(`/items/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
}
