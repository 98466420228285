import { Grid, TableRow, Tooltip } from '@mui/material';
import DataTableBodyCell from './DataTableBodyCell';
import { Can } from 'context';
import { useNavigate } from 'react-router-dom';
import { IconButton, Icon, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import MDButton from 'components/MDButton';
import { useState } from 'react';
import MDInput from 'components/MDInput';
import { addResources } from 'services/players';

function DataTableBodyRow({
  row,
  noEndBorder,
  object,
  noActions,
  openDelete,
  handleCloseDelete,
  handleDelete,
  handleOpenDelete,
  rowsLength,
  showAdd,
  setRefreshFilters,
  refreshFilters
}) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gems, setGems] = useState('');
  const [coins, setCoins] = useState('');
  function handleModal() {
    setIsModalOpen(!isModalOpen);
  }
  const handleAdd = async (id) => {
    handleModal();
    const res = await addResources(id, gems, coins);
    if (res.status === 200 || res.status === 201) {
      alert('Successfully added resource!');
      setRefreshFilters(refreshFilters.length ? '' : 'd');
    } else {
      alert('Something went wrong!');
    }
  };

  return (
    <TableRow {...row.getRowProps()}>
      {row.cells.map((cell, index) => (
        <DataTableBodyCell
          key={`player-data-${index}`}
          noBorder={noEndBorder && rowsLength - 1 === index}
          align={cell.column.align ? cell.column.align : 'left'}
          {...cell.getCellProps()}
          expanded={row.isExpanded}
        >
          {cell.render('Cell')}
        </DataTableBodyCell>
      ))}
      {showAdd && (
        <DataTableBodyCell noBorder={noEndBorder && rowsLength - 1 === key} width='0.5rem' align='left'>
          <Tooltip title='Add resources'>
            <IconButton color='info' onClick={() => handleModal()}>
              <Icon>add</Icon>
            </IconButton>
          </Tooltip>
        </DataTableBodyCell>
      )}
      {!noActions && (
        <DataTableBodyCell
          // noBorder={noEndBorder && rowsLength - 1 === index}
          width='0.5rem'
          align='left'
        >
          <Tooltip title='View'>
            <IconButton color='info' onClick={() => navigate(`show/${row?.original?.id}`, { replace: false })}>
              <Icon>visibility</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title='Edit'>
            <IconButton color='info' onClick={() => navigate(`edit/${row?.original?.id}`, { replace: false })}>
              <Icon>edit</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title='Delete'>
            <IconButton
              color='error'
              onClick={() => handleDelete(row?.original?.id)}
              // onClick={handleOpenDelete}
            >
              <Icon>delete</Icon>
            </IconButton>
          </Tooltip>
          <Dialog
            open={openDelete}
            onClose={handleCloseDelete}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{`Delete ${object}`}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Are you sure you want to delete this {object}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton variant='text' onClick={handleCloseDelete}>
                No
              </MDButton>
              <MDButton variant='text' color='error' onClick={() => handleDelete(row?.original?.id)}>
                yes
              </MDButton>
            </DialogActions>
          </Dialog>
        </DataTableBodyCell>
      )}
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          onClose={handleModal}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle
            // style={{ color: 'black' }}
            id='alert-dialog-title'
          >{`Add more resource to ${row?.values?.nickname}`}</DialogTitle>
          {console.log(row.original.id)}
          <DialogContent>
            <Grid container gap={5} pt={1}>
              <MDInput type='number' onChange={(e) => setGems(e.target.value)} value={gems} label='Gems'></MDInput>
              <MDInput type='number' onChange={(e) => setCoins(e.target.value)} value={coins} label='Coins'></MDInput>
            </Grid>
          </DialogContent>
          <DialogActions>
            {/* <MDButton
                    variant='text'
                    color='error'
                    onClick={() => {
                      handleModal();
                    }}
                  >
                    Не
                  </MDButton> */}
            <MDButton variant='text' onClick={() => handleAdd(row?.original?.id)}>
              Add
            </MDButton>
          </DialogActions>
        </Dialog>
      )}
    </TableRow>
  );
}

export default DataTableBodyRow;
