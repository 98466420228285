import useAxios from 'hooks/useAxios';

export async function getSkins() {
  const api = useAxios();

  try {
    const res = await api.get('/skins');
    return res;
  } catch (err) {
    return err;
  }
}
