import React, { useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import { useCookies } from 'react-cookie';
import MDTypography from 'components/MDTypography';

const LivePlayers = () => {
  const [activeUsers, setActiveUsers] = useState(0);
  const [cookie] = useCookies(['access_token']);
  const api = process.env.REACT_APP_SOCKET_URL;

  useEffect(() => {
    console.log('🔗 Connecting to WebSocket...');

    const socket = io(api, {
        // new way of sending the auth token
      //   auth: { token: cookie?.access_token },
      extraHeaders: {
        Authorization: cookie?.access_token
      }
      // transports: ['websocket'], // Remove to allow transport negotiation
    });

    socket.on('connect', () => {
      console.log('Successfully connected to WebSocket');

      // emit so we can get initial data
      socket.emit('activeUsersReport')
    });
    socket?.on('activeUsersReport', (data) => {
      console.log('Live players data received:', data);
      setActiveUsers(data.count);
    });

    return () => {
      socket.disconnect();
      console.log('Socket disconnected.');
    };
  }, []);

  return <MDTypography>Live Players: {activeUsers}</MDTypography>;
};

export default LivePlayers;
