import useAxios from 'hooks/useAxios';

export async function deleteSkin(id) {
  const api = useAxios();

  try {
    const res = await api.delete(`/skins/${id}`);
    return res;
  } catch (err) {
    return err;
  }
}
