import useAxios from 'hooks/useAxios';

export async function changePicture(id, smallImage, bigImage, coverImage) {
  const api = useAxios();
  const formData = new FormData();
  formData.append('smallImage', smallImage);
  formData.append('bigImage', bigImage);
  formData.append('coverImage', coverImage);

  try {
    const res = await api.post(`/skins/change-images/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return res;
  } catch (err) {
    return err;
  }
}
