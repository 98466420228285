import useAxios from 'hooks/useAxios';

export async function editSkin(id, name, unrealAvatarId, priceGems, priceCoins, rarity) {
  const api = useAxios();
  const payload = {
    name,
    priceGems,
    priceCoins,
    unrealSkinId: unrealAvatarId,
    rarity
  };

  try {
    const res = await api.patch(`/skins/${id}`, payload);
    return res;
  } catch (err) {
    return err;
  }
}
